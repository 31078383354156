header#header{
  div[data-row="top"]{
    max-width: 1298px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    margin: auto;
    >.ct-container{
      max-width: 1170px!important;
      div[data-id="YyxByg"]{
        img{
          width: 15px;
          height: 15px;
          margin-bottom: 1px;
          filter: brightness(0)
          saturate(100%) invert(73%) sepia(17%)
          saturate(191%) hue-rotate(179deg)
          brightness(86%) contrast(88%);
        }
      }
     }
  }
  div[data-row="middle"]{
    >.ct-container{
      max-width: 1170px!important;
    }
  }
}
.ct-drawer-canvas{
  .ct-panel-content[data-device="mobile"]{
    align-items: center !important;
    flex-direction: row!important;
    flex-wrap: wrap!important;
    flex: initial!important;
    div[data-id="YyxByg"]{
      margin: 0 0 0 10px!important;
      img{
        width: 16px;
        height: 16px;
        margin-bottom: 1px;
        filter: brightness(0)
        saturate(100%) invert(73%) sepia(17%)
        saturate(191%) hue-rotate(179deg)
        brightness(86%) contrast(88%);
      }
    }
    >*{
      width: 100%;
    }
    div[data-id="socials"],
    div[data-id="YyxByg"]{
      width: auto;
    }
  }

}