footer {
  div[data-row="middle"] {
    > .ct-container {
      max-width: 1170px !important;
      @include break-above(md2) {
        grid-template-columns: minmax(200px, 370px) auto auto minmax(200px, 225px);

      }

      .footer_spacer {
        margin: 23px 0;
        height: 1px;
        opacity: .2;
      }

      .email,
      .phone {
        p{
          margin: 0;
        }
        @include break-below(sm) {
          flex-wrap: nowrap;
        }
      }

    }

  }

  div[data-row="bottom"] {
    > .ct-container {
      max-width: 1170px !important;
    }
  }
}