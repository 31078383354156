.border_radius_6{
  img{
    border-radius: 6px!important;
  }
}

.border_radius_12{
  img{
    border-radius: 12px!important;
  }
}
