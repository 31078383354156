
.content_page_img_col2 {
  > .gb-grid-column {
    &:last-child {
      @include break-between(sm, md2) {
        width: 40%;
        flex-basis: 40%;
      }
    }
  }
}

.table_4_cols {
  &:before{
    content: '';
  }
  table {
    border-color: #fff;
    @include break-below(md2) {
      max-width: initial !important;
      width: 966px !important;
    }

    thead {
      th {
        border-color: #fff;
        border-width: 3px;
        width: 40%;
        @include break-below(md2) {
          width: 30%;
        }

        &:not(:first-child) {
          width: 20%;
          color: #fff;
          background-color: var(--paletteColor2);
        }

        strong {
          font-size: 24px;
          font-weight: 800;
        }
      }
    }

    tbody {

      tr {
        &:nth-child(odd) {
          td {
            &:not(:first-child) {
              background-color: #f3f4f6;
            }
          }
        }

        td {
          border-color: #fff;
          border-width: 3px;
          transition: padding .3s , line-heght .3s;

        }
        &:last-child{
          td{

            padding: 0;
            strong{
              color: var(--paletteColor4);
            display: block;
              padding: 8px 0;
              border: 2px solid #f3f4f6;
              cursor: pointer;
              &:hover{
                color: var(--paletteColor1);
              }
            }
          }
        }

      }


    }
  }
}
.show_less{
  tbody {
    tr {
      &:nth-child(n+10):not(:last-child){
        td{
          padding: 0;
          line-height: 0;
          overflow: hidden;
          margin: 0;
          border: 0;
          img{
            display: none;
          }
        }
      }
    }
  }
}
.referenties_separator{
  background-color: transparent!important;
  border-bottom: 1px dashed #d1d5db!important;
}