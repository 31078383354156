//Hero section
.home_hero {
  h1, h2 {
    width: fit-content;
  }
}

.home_section_1 {
  .gb-container {
    position: relative;
    box-shadow: 0 12px 24px -12px rgba(17, 24, 39, 0.3);

    .card-link {
      font-style: normal;
      font-weight: 700;
      text-transform: capitalize;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      margin: 0;

      a {
        display: block;
        height: 100%;
        width: 100%;
        color: transparent;
      }
    }
  }
}

.home_section_2 {
  .video_gradient {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-image: radial-gradient(circle at 50% 50%, rgba(17, 24, 39, 0.2), rgba(17, 24, 39, 0.7) 76%);
      border-radius: 12px;

    }

    .video_container {
      .video {
        background-color: transparent;
      }
    }
  }
}

.home_section_6 {
  .ct-posts-shortcode {
    .entries {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      @include break-below(md2) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      @include break-below(xs) {
        grid-template-columns: repeat(1, minmax(0, 1fr));

      }
      .entry-meta{
        display: none;
      }
    }
  }
}

.above_footer_serction {
  position: relative;

  .footer_img {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    height: 100%;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to right, #ff9600 0, #fff0 50%);
    }

    @include break-below(sm) {
      position: absolute;
      top: auto;
      right: 0;
      width: 100%;
      height: 367px;
      bottom: 0;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

//.slider_section {
//  position: relative;
//  >.swiper:not(.swiper_navigation)  {
//    padding: 30px 18px;
//    .swiper-wrapper {
//      align-items: center!important;
//      .swiper-slide {
//        .img-container{
//          background-color: #fff;
//          box-shadow: 0 12px 24px -12px rgba(17, 24, 39, 0.2);
//          @media(max-width: 640px){
//            margin-left: 14px;
//            margin-right: 14px;
//          }
//          img {
//            height: 124px;
//            object-fit: contain;
//            width: 100%;
//          }
//        }
//
//      }
//    }
//
//  }
//  .swiper_navigation{
//    overflow: visible;
//    height: 50px;
//    width: 100%;
//    position: initial;
//    .swiper-button-next,
//    .swiper-button-prev{
//      top: 39%;
//      &:after{
//        content: '';
//      background-image: url("/wp-content/uploads/2022/02/arrow-right-solid.svg");
//        height: 100%;
//        width: 100%;
//        background-repeat: no-repeat;
//        background-position: center;
//        background-size: 17px;
//        opacity: .8;
//      }
//    }
//    .swiper-button-next{
//      right: -100px;
//      @media(max-width: 1220px){
//        right: -20px;
//      }
//    }
//    .swiper-button-prev{
//      left: -100px;
//      &:after{
//        transform: scaleX(-1);
//      }
//      @media(max-width: 1220px){
//        left: -20px;
//      }
//    }
//    .swiper-pagination{
//      span{
//        background: #d1d5db!important;
//        opacity: 1!important;
//        transition: .3s ease-in-out!important;
//      }
//      .swiper-pagination-bullet-active{
//        background: var(--paletteColor1)!important;
//        width: 24px;
//        border-radius: 100px;
//      }
//    }
//
//  }
//}

//.scroll_section {
//  display: flex;
//  flex-wrap: wrap;
//  gap: 30px;
//
//  .nav_column {
//    position: relative;
//    padding: 0;
//    width: calc(20% - 15px);
//
//    .scroll_nav {
//      position: sticky;
//      top: 40px;
//      padding: 5px 16px;
//      border: 1px solid #e5e7eb;
//      max-height: 92vh;
//      overflow: auto;
//      @include break-below(sm) {
//        display: grid;
//        grid-template-columns: 1fr 1fr;
//        column-gap: 24px;
//        max-height: initial;
//
//      }
//      @media(max-width: 450px){
//        grid-template-columns: 1fr;
//        column-gap: 0;
//      }
//      .menu_item {
//        list-style: none;
//        position: relative;
//        padding: 10px 0;
//        margin: 0;
//        cursor: pointer;
//
//        &:not(:first-child) {
//          border-top: 1px dashed #e5e7eb;
//        }
//
//        @media(min-width: 450px) and (max-width: 1024px) {
//          &:nth-child(2){
//            border: 0;
//          }
//        }
//
//        &:before {
//          content: '';
//          position: absolute;
//          left: 0;
//          top: 0;
//          z-index: -1;
//          height: 100%;
//          width: 14px;
//          background-image: url(/wp-content/uploads/2022/02/arrow-right-solid.svg);
//          background-position: center;
//          background-repeat: no-repeat;
//          background-size: 10px;
//          filter: brightness(0) saturate(100%) invert(59%) sepia(16%) saturate(3433%) hue-rotate(360deg) brightness(103%) contrast(104%);
//        }
//
//        span {
//          transition: .3s ease;
//          position: relative;
//          display: block;
//
//          &:before {
//            content: '';
//            position: absolute;
//            left: 0;
//            top: 0;
//            z-index: -1;
//            height: 100%;
//            background-color: #fff;
//            width: 100%;
//          }
//
//        }
//
//        &.active {
//          @include break-above(sm) {
//            span {
//              color: var(--paletteColor1);
//              margin-left: 19px;
//            }
//          }
//        }
//      }
//    }
//
//  }
//
//  .content_column {
//    width: calc(80% - 15px);
//
//    .section_block {
//      &:not(:last-child) {
//        margin-bottom: 30px;
//
//      }
//
//      .section_title {
//        font-size: 24px;
//        font-weight: 800;
//        background-color: var(--paletteColor2);
//        color: #fff;
//        padding: 16px 24px;
//        border-radius: 6px;
//        margin-bottom: 30px;
//      }
//
//      .section_gallery {
//        display: flex;
//        flex-wrap: wrap;
//        gap: 30px;
//
//        .galley_item {
//          width: calc(20% - 24px);
//
//          .img_resize {
//            position: relative;
//            padding-top: 100%;
//            border-radius: 6px;
//            transition: .4s ease-in-out;
//            box-shadow: 0 3px 8px -2px #11182717;
//            border: solid 1px #e5e7eb;
//            @include break-above(md2) {
//              opacity: .6;
//              &:hover {
//                opacity: 1;
//              }
//
//            }
//
//            img {
//              position: absolute;
//              height: 85%;
//              width: 85%;
//              transform: translate(-50%, -50%);
//              top: 50%;
//              left: 50%;
//              object-fit: contain;
//            }
//          }
//        }
//      }
//    }
//  }
//
//  @include break-below(md2) {
//    .nav_column {
//      width: calc(30% - 15px);
//
//    }
//    .content_column {
//      width: calc(70% - 15px);
//    }
//  }
//  @include break-below(sm) {
//    .nav_column, .content_column {
//      width: 100%;
//    }
//
//  }
//}



